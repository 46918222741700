.image_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
}

.image_label {
  border: 3px dashed #d9d8da;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10rem;
  text-transform: capitalize;
  color: #605d66;
  font-weight: 600;
  cursor: pointer;
}

.image_preview {
  /* composes: imageLabel; */
  border: none;
}

.container .body .gallery .image_preview img {
  width: 100%;
  height: 10rem;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.container {
  padding: 16px;
}

.container .header {
  font-size: 24px;
  font-weight: bolder;
  border-bottom: 1px solid #d9d8da;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .approve {
  border: none;
  outline: none;
  background: #549a9a;
  color: #fff;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: 31px;
  padding-left: 32px;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  align-self: flex-start;
  border-radius: 5px;
}

.container .approve:hover {
  opacity: 0.7;
}

.container .decline {
  border: none;
  outline: none;
  background: red;
  color: #fff;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: 31px;
  padding-left: 32px;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  align-self: flex-start;
  border-radius: 5px;
}

.container .decline:hover {
  opacity: 0.7;
}

.container .body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 24px;
}

.container .form_body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.container .body .label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.container input {
  border: 1px solid #d9d8da;
  border-radius: 5px;
  max-width: 100%;
  width: 100%;
  height: 40px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: block;
}

.container select {
  border: 1px solid #d9d8da;
  border-radius: 5px;
  max-width: 100%;
  width: 100%;
  height: 40px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: block;
}

.container .body .select_box {
  margin-bottom: 10px;
  border-radius: 5px;
}

.container textarea {
  border: 1px solid #d9d8da;
  border-radius: 5px;
  max-width: 100%;
  width: 100%;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 12px;
  box-sizing: border-box;
  display: block;
}

.container .body .gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
}

.save {
  border: none;
  width: auto;
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
  background: #549a9a;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

.save:disabled {
  border: none;
  width: auto;
  border-radius: 8px;
  height: 40px;
  opacity: 0.5;
  cursor: pointer;
  background: #549a9a;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

/* .container .body .gallery .image */

.container .body .button_container {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.container .body .button_container .save {
  border: none;
  width: 100%;
  margin-top: 24px;
  border-radius: 8px;
  height: 45px;
  cursor: pointer;
  background: #549a9a;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.container .body .button_container .save:disabled {
  border: none;
  width: 100%;
  margin-top: 24px;
  border-radius: 8px;
  height: 45px;
  opacity: 0.5;
  cursor: pointer;
  background: #549a9a;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.container .body .button_container .back {
  border: 1px solid #549a9a;
  width: 100%;
  border-radius: 8px;
  height: 45px;
  cursor: pointer;
  background: transparent;
  color: #549a9a;
  font-size: 18px;
  font-weight: bold;
}

.container .body .button_container .back:disabled {
  border: 1px solid #549a9a;
  width: 100%;
  border-radius: 8px;
  height: 45px;
  cursor: pointer;
  background: transparent;
  color: #549a9a;
  font-size: 18px;
  font-weight: bold;
  opacity: 0.5;
}

.container .body .price_container {
  display: flex;
  border: 1px solid #d9d8da;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.container .body .price_container input {
  border: none;
  border-radius: 0px;
  border-left: 1px solid #d9d8da;
  padding-left: 16px;
  margin-bottom: 0;
  height: auto;
  background-color: transparent;
}

.container .body .price_container select {
  border: none;
  margin-bottom: 0;
  height: auto;
  width: auto;
}

.comment_button {
  width: 100%;
  margin-top: 16px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  border: none;
  background: #549a9a;
  font-size: 20px;
  cursor: pointer;
}

.comment_button:disabled {
  opacity: 0.4;
}

.payment_input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 0.5px solid #d9d8da;
  padding: 5px;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.label {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.selection_box {
  display: flex;
}

.selection {
  border: 1px solid #828282;
  display: grid;
  place-items: center;
  color: #828282;
  height: 40px;
  cursor: pointer;
  font-size: 15px;
  width: 100px;
  cursor: pointer;
}

.selection:nth-child(2) {
  border-left: none;
}

.selection_active {
  border: 1px solid #549a9a;
  display: grid;
  background: #549a9a;
  place-items: center;
  color: #fff;
  height: 40px;
  cursor: pointer;
  font-size: 15px;
  width: 100px;
  cursor: pointer;
}
