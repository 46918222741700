.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #ccc;
  transition: 0.4s;
  border-radius: 35px;
}

.slider::before {
  position: absolute;
  content: ' ';
  height: 20px;
  width: 20px;
  left: 4px;
  top: 2px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #549a9a;
}

input:checked + .slider::before {
  transform: translateX(17px);
}
