.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #e0e0e0;
}

.activecircle {
  composes: circle;
  background-color: #549a9a;
}

.line {
  width: 15rem;
  height: 0.25rem;
  background-color: #e0e0e0;
}

.activeline {
  composes: line;
  background-color: #549a9a;
}

.steplabel,
.activesteplabel {
  width: 38rem;
  display: flex;
  justify-content: space-between;
  padding-left: 1.2rem;
}

.steplabel p {
  display: flex;
  justify-content: center;
  padding-left: 1.5rem;
  margin-bottom: 0.25rem;
  color: #e0e0e0;
  font-size: 0.625rem;
  font-weight: 500;
}

.active {
  color: #549a9a !important;
}

.steplabel p:nth-child(2),
.activesteplabel p:nth-child(2) {
  padding-left: 2.5rem;
}

.steplabel p:nth-child(1),
.activesteplabel p:nth-child(1) {
  padding-left: 1rem;
}
select {
  border: 1px solid #e0e0e0;
  border-radius: 0.3125rem;
  padding-left: 1rem;
  height: 2.5rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
}
