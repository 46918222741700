.container {
  display: grid;
  grid-template-columns: auto 368px;
  gap: 24px;
  margin-top: 24px;
}

.card {
  background: rgba(248, 248, 248, 0.2);
  border: 1px solid #ededed;
  border-radius: 10px;
  width: auto;
  padding: 24px;
  margin-bottom: 24px;
}

.label {
  font-size: 18px;
  font-weight: 900;
  color: #000;
  margin-bottom: 16px;
}

.sub {
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
}

.sub_text {
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  color: #605d66;
}

.line {
  border-bottom: 1px solid #d9d8da;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;
}

.center {
  display: grid;
  place-items: center;
  margin-top: 24px;
}

.center_label {
  color: #d9d8da;
  font-size: 16px;
}

.pay {
  border: none;
  outline: none;
  background: #549a9a;
  color: #fff;
  padding: 13px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
}

.location_card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  border-bottom: 1px solid #d9d8da;
  padding-top: 16px;
  padding-bottom: 16px;
}

.location_card:last-child {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  border-bottom: none;
  padding-top: 16px;
  padding-bottom: 16px;
}

.location {
  font-size: 16px;
  font-weight: 600;
}

.location_t {
  font-size: 13px;
  font-weight: 600;
  margin-top: 5px;
  color: #9c9c9c;
  text-transform: capitalize;
  text-decoration: none;
}

.image_box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.image_box img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  cursor: pointer;
}

.profile {
  display: flex;
  align-content: baseline;
  gap: 10px;
}

.profile img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.name {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 2px;
}

.email {
  color: #9c9c9c;
}

.others {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}
