.label {
  margin-bottom: 8px;
}

.container input {
  border: 1px solid #d9d8da;
  border-radius: 5px;
  max-width: 100%;
  width: 100%;
  height: 40px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: block;
}

.container textarea {
  border: 1px solid #d9d8da;
  border-radius: 5px;
  max-width: 100%;
  width: 100%;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-bottom: 12px;
  box-sizing: border-box;
  display: block;
}

.container button {
  border: none;
  width: 100%;
  margin-top: 24px;
  border-radius: 8px;
  height: 45px;
  cursor: pointer;
  background: #549a9a;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.container button:disabled {
  border: none;
  width: 100%;
  margin-top: 24px;
  border-radius: 8px;
  height: 45px;
  opacity: 0.5;
  cursor: pointer;
  background: #549a9a;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.container img {
  width: 100%;
  height: 6rem;
  object-fit: contain;
}
